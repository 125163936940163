import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const Nav = styled.nav`
`;

const NavList = styled.ul`
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
`;

const NavItem = styled.li`
  display: inline;
  float: ${props => props.float};
`;

const StyledLink = (props) => {
  const style = `
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    text-decoration: none;`
  const MyLink = styled(Link)`
    ${style}
  `;
  const MySpan = styled.span`
    ${style}
  `;

  if (props.to) {
    return <MyLink to={props.to}>{props.children}</MyLink>
  }
  return <MySpan>{props.children}</MySpan>
}

export default function ArtPagedLayout({pageContext}) {

  const image = getImage(pageContext.childImageSharp)

  return (
    <div>
        <Nav>
          <NavList>
            <NavItem float="left">
              <StyledLink to={pageContext.previousPath}><span aria-hidden="true">&larr;</span> Previous</StyledLink>          
            </NavItem>
            <NavItem>
              <StyledLink to={pageContext.archivePath}>Archive</StyledLink>          
            </NavItem>
            <NavItem float="right">
              <StyledLink to={pageContext.nextPath}>Next <span aria-hidden="true">&rarr;</span></StyledLink>              
            </NavItem>
          </NavList>
        </Nav>
        <h1>{pageContext.title}</h1>
        <GatsbyImage image={image} alt={pageContext.title} />
        <footer>
          &copy; J Parkin {pageContext.copyrightYear}.
        </footer>
    </div>
  )
}
